<template>
  <v-form ref="form">
    <p style="text-align: justify">
      Keď idú veci zle v našich životoch alebo nefungujú tak, ako sme dúfali a my cítime, že to mohlo byť lepšie,
      niekedy máme negatívne a sebakritické myšlienky a pocity. Tie sa môžu vyskytovať vo forme pocitov bezcennosti,
      neužitočnosti alebo menejcennosti. Avšak ľudia sa môžu pokúšať podporovať samých seba. Pod textom nájdete
      zoznam myšlienok a pocitov, ktoré ľudia niekedy majú. Prečítajte si každý výrok pozorne a vyberte číslo,
      ktoré najlepšie popisuje, nakoľko je výrok pre vás pravdivý. Prosím použite nasledovnú škálu.
    </p>
    <p><b>0 - vôbec nie ako ja, 1 - trochu ako ja, 2 - mierne ako ja, 3 - celkom ako ja, 4 - veľmi ako ja </b></p>
    <hr>
    <br>
    <h3>Keď sa mi nedarí:</h3>
    <div v-for="question in inputList" :key="question">
      <v-radio-group v-model="radioInputValues[question]" :rules="rules.required">
        <p>{{ question }}</p>
        <v-row dense>
          <v-radio
              class="pl-5"
              v-for="n in 5"
              :key="n-1"
              :label="`${n-1}`"
              :value="n-1"
          />
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="position: absolute; right: 10px;" color="blue" v-bind="attrs" v-on="on">info</v-icon>
            </template>
            <span>0 - vôbec nie ako ja, 1 - trochu ako ja, 2 - mierne ako ja, 3 - celkom ako ja, 4 - veľmi ako ja </span>
          </v-tooltip>
        </v-row>
      </v-radio-group>
      <hr>
    </div>
  </v-form>
</template>

<script>
export default {
  name: "StepFscrs",
  data: () => ({
    rules: {
      required: [value => (!!value || value === 0) || "Toto pole je povinné."]
    },
    inputList: [
        "1. Ľahko som zo seba sklamaný/á.",
        "2. Je tu časť mňa, ktorá ma znevažuje.",
        "3. Dokážem si pripomenúť veci, ktoré sú na mne pozitívne.",
        "4. Je náročné, ovládať môj hnev alebo frustráciu zo seba.",
        "5. Viem si sám sebe ľahko odpustiť.",
        "6. Je tu časť mňa, ktorá cíti, že nie som dosť dobrý/á.",
        "7. Cítim sa ubíjaný/á svojimi vlastnými sebakritickými myšlienkami.",
        "8. Napriek tomu som rád/rada, sám/sama sebou.",
        "9. Tak sa na seba nahnevám, že si chcem ublížiť alebo sa zraniť.",
        "10. Mám pocit znechutenia zo seba.",
        "11. Stále sa môžem cítiť milovaný/á a akceptovaný/á.",
        "12. Prestávam sa o seba starať.",
        "13. Príde mi ľahké mať rád/a samého seba.",
        "14. Pamätám si a vraciam sa k mojim zlyhaniam.",
        "15. Nadávam si.",
        "16. Som láskavý/á a podporujúci/a voči sebe.",
        "17. Nedokážem akceptovať zlyhania a prekážky bez pocitu nedostatočnosti.",
        "18. Myslím, že si moju sebakritiku zaslúžim.",
        "19. Som schopný/á sa o seba postarať a dávať si na seba pozor.",
        "20. Jedna časť mňa, sa chce zbaviť častí mňa, ktoré na sebe nemám rád/a.",
        "21. Povzbudzujem sám seba ohľadom svojej budúcnosti.",
        "22. Nie som rád/a sám sebou.",
    ],
    radioInputValues: {},
  }),
  methods: {
    next() {
      if(!this.$refs.form.validate()) {
        return;
      }
      this.$emit("saveFSCRS", this.radioInputValues);
    }
  }
}
</script>
