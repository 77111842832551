<template>
  <v-app>
    <v-card elevation="0">
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="5" md="6" sm="8" class="mx-auto">
              <v-form @submit.prevent="save">
                <v-stepper v-model="step" rounded="xl" elevation="20">
                  <v-stepper-header>
                    <v-stepper-step
                        step="1"
                    >
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="2">

                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4">
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="5">
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="6">
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-content step="1">
                    <form @submit.prevent="save">
                      <step1 ref="step1" @saveEmail="saveEmail"/>
                    </form>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <form @submit.prevent="save">
                      <step2 ref="step2" @saveUserData="saveUserData"></step2>
                    </form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-card-title>
                      <span>FSCRS</span>
                    </v-card-title>
                    <form @submit.prevent="save">
                      <step-fscrs ref="step3" @saveFSCRS="saveFSCRS"/>
                    </form>
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <v-card-title>
                      <span>SCS</span>
                    </v-card-title>
                    <form @submit.prevent="save">
                      <step-scs ref="step4" @saveSCS="saveSCS"/>
                    </form>
                  </v-stepper-content>

                  <v-stepper-content step="5">
                    <form @submit.prevent="save">
                      <step-pictures
                          ref="step5"
                          :pictures-with-additional-question="pictures_with_additional_question"
                          :pictures="pictures"
                          @fromStep="saveObject"
                          @changeNextButtonText="changeNextButtonText"
                      />
                    </form>
                  </v-stepper-content>

                  <v-stepper-content step="6">
                    <span>Ďakujeme za Váš čas a energiu.</span>
                  </v-stepper-content>

                  <div v-if="step !== 6" style="height: 40px; background-color: darkgray" class="text-center">
                    <v-btn width="300px" text plain @click="stepper()">
                      {{ nextButtonText }}
                    </v-btn>
                  </div>
                </v-stepper>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import Step1 from "@/components/Step1";
import Step2 from "@/components/Step2";
import StepPictures from "@/components/StepPictures";
import StepFscrs from "@/components/StepFscrs";
import StepScs from "@/components/StepScs";

export default {
  name: 'App',
  components: {
    Step1,
    Step2,
    StepPictures,
    StepFscrs,
    StepScs,
  },
  created() {
    document.title = "Dotazník k DP";
  },
  data: () => ({
    step: 1,
    nextButtonText: "Ďalej",
    allowSave: false,
    allowStep6: false,
    radio: ["áno", "nie"],
    dataToSend: {
      email: "",
      fscrs: {},
      scs: {},
      picture_form: [],
      userData: {},
    },
    pictures: [
      "emotions/tsr/Sample_005_anger.jpg",
      "emotions/tsr/Sample_005_contempt.jpg",
      "emotions/tsr/Sample_005_disgust.jpg",
      "emotions/tsr/Sample_005_fear.jpg",
      "emotions/tsr/Sample_005_happy.jpg",
      "emotions/tsr/Sample_005_neutral.jpg",
      "emotions/tsr/Sample_005_sadness.jpg",
      "emotions/tsr/Sample_005_surprise.jpg",
      "emotions/tsr/Sample_033_anger.jpg",
      "emotions/tsr/Sample_033_contempt.jpg",
      "emotions/tsr/Sample_033_disgust.jpg",
      "emotions/tsr/Sample_033_fear.jpg",
      "emotions/tsr/Sample_033_happy.jpg",
      "emotions/tsr/Sample_033_neutral.jpg",
      "emotions/tsr/Sample_033_sadness.jpg",
      "emotions/tsr/Sample_033_surprise.jpg",
      "emotions/tsr/Sample_053_anger.jpg",
      "emotions/tsr/Sample_053_contempt.jpg",
      "emotions/tsr/Sample_053_disgust.jpg",
      "emotions/tsr/Sample_053_fear.jpg",
      "emotions/tsr/Sample_053_happy.jpg",
      "emotions/tsr/Sample_053_neutral.jpg",
      "emotions/tsr/Sample_053_sadness.jpg",
      "emotions/tsr/Sample_053_surprise.jpg",
      "emotions/tsr/Sample_092_anger.jpg",
      "emotions/tsr/Sample_092_contempt.jpg",
      "emotions/tsr/Sample_092_disgust.jpg",
      "emotions/tsr/Sample_092_fear.jpg",
      "emotions/tsr/Sample_092_happy.jpg",
      "emotions/tsr/Sample_092_neutral.jpg",
      "emotions/tsr/Sample_092_sadness.jpg",
      "emotions/tsr/Sample_092_surprise.jpg",
      "emotions/tsr/Sample_105_anger.jpg",
      "emotions/tsr/Sample_105_contempt.jpg",
      "emotions/tsr/Sample_105_disgust.jpg",
      "emotions/tsr/Sample_105_fear.jpg",
      "emotions/tsr/Sample_105_happy.jpg",
      "emotions/tsr/Sample_105_neutral.jpg",
      "emotions/tsr/Sample_105_sadness.jpg",
      "emotions/tsr/Sample_105_surprise.jpg",
      "emotions/tsr/Sample_115_anger.jpg",
      "emotions/tsr/Sample_115_contempt.jpg",
      "emotions/tsr/Sample_115_disgust.jpg",
      "emotions/tsr/Sample_115_fear.jpg",
      "emotions/tsr/Sample_115_happy.jpg",
      "emotions/tsr/Sample_115_neutral.jpg",
      "emotions/tsr/Sample_115_sadness.jpg",
      "emotions/tsr/Sample_115_surprise.jpg",
      "emotions/tbr/Sample_005_anger.jpg",
      "emotions/tbr/Sample_005_contempt.jpg",
      "emotions/tbr/Sample_005_disgust.jpg",
      "emotions/tbr/Sample_005_fear.jpg",
      "emotions/tbr/Sample_005_happy.jpg",
      "emotions/tbr/Sample_005_neutral.jpg",
      "emotions/tbr/Sample_005_sadness.jpg",
      "emotions/tbr/Sample_005_surprise.jpg",
      "emotions/tbr/Sample_033_anger.jpg",
      "emotions/tbr/Sample_033_contempt.jpg",
      "emotions/tbr/Sample_033_disgust.jpg",
      "emotions/tbr/Sample_033_fear.jpg",
      "emotions/tbr/Sample_033_happy.jpg",
      "emotions/tbr/Sample_033_neutral.jpg",
      "emotions/tbr/Sample_033_sadness.jpg",
      "emotions/tbr/Sample_033_surprise.jpg",
      "emotions/tbr/Sample_053_anger.jpg",
      "emotions/tbr/Sample_053_contempt.jpg",
      "emotions/tbr/Sample_053_disgust.jpg",
      "emotions/tbr/Sample_053_fear.jpg",
      "emotions/tbr/Sample_053_happy.jpg",
      "emotions/tbr/Sample_053_neutral.jpg",
      "emotions/tbr/Sample_053_sadness.jpg",
      "emotions/tbr/Sample_053_surprise.jpg",
      "emotions/tbr/Sample_092_anger.jpg",
      "emotions/tbr/Sample_092_contempt.jpg",
      "emotions/tbr/Sample_092_disgust.jpg",
      "emotions/tbr/Sample_092_fear.jpg",
      "emotions/tbr/Sample_092_happy.jpg",
      "emotions/tbr/Sample_092_neutral.jpg",
      "emotions/tbr/Sample_092_sadness.jpg",
      "emotions/tbr/Sample_092_surprise.jpg",
      "emotions/tbr/Sample_105_anger.jpg",
      "emotions/tbr/Sample_105_contempt.jpg",
      "emotions/tbr/Sample_105_disgust.jpg",
      "emotions/tbr/Sample_105_fear.jpg",
      "emotions/tbr/Sample_105_happy.jpg",
      "emotions/tbr/Sample_105_neutral.jpg",
      "emotions/tbr/Sample_105_sadness.jpg",
      "emotions/tbr/Sample_105_surprise.jpg",
      "emotions/tbr/Sample_115_anger.jpg",
      "emotions/tbr/Sample_115_contempt.jpg",
      "emotions/tbr/Sample_115_disgust.jpg",
      "emotions/tbr/Sample_115_fear.jpg",
      "emotions/tbr/Sample_115_happy.jpg",
      "emotions/tbr/Sample_115_neutral.jpg",
      "emotions/tbr/Sample_115_sadness.jpg",
      "emotions/tbr/Sample_115_surprise.jpg",
    ],
    pictures_with_additional_question: [
      "emotions/tsr/Sample_033_anger.jpg",
      "emotions/tsr/Sample_092_anger.jpg",
      "emotions/tsr/Sample_005_contempt.jpg",
      "emotions/tsr/Sample_092_contempt.jpg",
      "emotions/tsr/Sample_033_disgust.jpg",
      "emotions/tsr/Sample_053_disgust.jpg",
      "emotions/tsr/Sample_115_fear.jpg",
      "emotions/tsr/Sample_053_fear.jpg",
      "emotions/tsr/Sample_005_happy.jpg",
      "emotions/tsr/Sample_105_happy.jpg",
      "emotions/tsr/Sample_033_neutral.jpg",
      "emotions/tsr/Sample_092_neutral.jpg",
      "emotions/tsr/Sample_092_sadness.jpg",
      "emotions/tsr/Sample_005_sadness.jpg",
      "emotions/tsr/Sample_033_surprise.jpg",
      "emotions/tsr/Sample_105_surprise.jpg",
      "emotions/tbr/Sample_005_anger.jpg",
      "emotions/tbr/Sample_053_anger.jpg",
      "emotions/tbr/Sample_033_contempt.jpg",
      "emotions/tbr/Sample_105_contempt.jpg",
      "emotions/tbr/Sample_005_disgust.jpg",
      "emotions/tbr/Sample_092_disgust.jpg",
      "emotions/tbr/Sample_105_fear.jpg",
      "emotions/tbr/Sample_005_fear.jpg",
      "emotions/tbr/Sample_033_happy.jpg",
      "emotions/tbr/Sample_033_happy.jpg",
      "emotions/tbr/Sample_053_neutral.jpg",
      "emotions/tbr/Sample_005_neutral.jpg",
      "emotions/tbr/Sample_033_sadness.jpg",
      "emotions/tbr/Sample_105_sadness.jpg",
      "emotions/tbr/Sample_092_surprise.jpg",
      "emotions/tbr/Sample_005_surprise.jpg",
    ],
  }),
  methods: {
    stepper() {
      if(this.step === 1) {
        this.$refs.step1.next();
        return;
      }
      if(this.step === 2) {
        this.$refs.step2.next();
        return;
      }
      if(this.step === 3) {
        this.$refs.step3.next();
        return;
      }
      if(this.step === 4) {
        this.$refs.step4.next();
        return;
      }
      if(this.step === 5 && !this.allowSave) {
        this.$refs.step5.next();
        return;
      }
      if(this.step === 5 && this.allowSave) {
        this.step++;
        return;
      }
    },
    saveUserData(data) {
      this.dataToSend.userData = data;
      this.step++;
    },
    saveEmail(data) {
      this.dataToSend.email = data;
      this.step++;
    },
    saveFSCRS(data) {
      this.dataToSend.fscrs = data;
      this.step++;
    },
    saveSCS(data) {
      this.dataToSend.scs = data;
      this.step++;
    },
    saveObject(data) {
      this.dataToSend.picture_form.push(data.obj);
      this.allowSave = data.allow;
      if(data.allow) {
        this.step++;
        this.save();
      }
    },
    changeNextButtonText() {
      this.nextButtonText = "Odoslať";
    },
    save() {
      // Local => http://127.0.0.1:8000/save-response
      this.$http.post("https://api.app.laco.ovh/save-response", this.dataToSend)
      .catch(() => {
        // TODO: Send data to PHP application
        console.log("shit");
      })
    },
  }
};
</script>
